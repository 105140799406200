import {
  PERFORMED_TREATMENT,
  PREDICAMENT_STATE_UNKNOWN,
  PREDICAMENT_STATE_YES,
  REHAB_ADMISSION,
  REHAB_FORM_NAMES,
  TRANSPORT_TYPE_AMBULANCE_LYING_DOWN,
  TRANSPORT_TYPE_AMBULANCE_SITTING,
  TRANSPORT_TYPE_EMERGENCY_VEHICLE,
  TRANSPORT_TYPE_HELICOPTER,
  TRANSPORT_TYPE_PERSONAL_CAR,
  TRANSPORT_TYPE_PUBLIC_TRANSPORT,
  TRANSPORT_TYPE_TAXI,
  TRANSPORTATION_TYPE,
} from "core/consts";
import {
  Auction,
  GetOntologyType,
  PatientTransport,
  PatientTransportType,
  TransportType,
} from "core/types";
import Translations from "translations/types";
import { RehabFormFormGetters } from "../configs";
import {
  auctionBirthdatetoUnix,
  booleanToPredicamentState,
  getICDCode,
  getInfection,
  INFECTIONS_CHARACTER_LIMIT,
} from "../utils";

export const getTransportMapping = (
  transportType: PatientTransport["transport_type"] | undefined | null,
): TransportType | null => {
  if (!transportType || transportType.length !== 1) return null;

  const transportTypeMap: Record<PatientTransportType, TransportType | null> = {
    [TRANSPORT_TYPE_AMBULANCE_SITTING]: TRANSPORTATION_TYPE.AMBULANCE,
    [TRANSPORT_TYPE_AMBULANCE_LYING_DOWN]: TRANSPORTATION_TYPE.AMBULANCE,
    [TRANSPORT_TYPE_TAXI]: TRANSPORTATION_TYPE.TAXI,
    [TRANSPORT_TYPE_EMERGENCY_VEHICLE]: TRANSPORTATION_TYPE.AMBULANCE,
    [TRANSPORT_TYPE_PERSONAL_CAR]: TRANSPORTATION_TYPE.CAR,
    [TRANSPORT_TYPE_PUBLIC_TRANSPORT]: TRANSPORTATION_TYPE.PUBLIC,
    [TRANSPORT_TYPE_HELICOPTER]: null,
  };

  return transportTypeMap[transportType[0]];
};

export const importAuctionToGKVMedicalForm = (
  getOntology: GetOntologyType,
  translations: Translations,
): RehabFormFormGetters<typeof REHAB_FORM_NAMES.GKV_MEDICAL> => ({
  patient_first_name: (auction: Auction) =>
    auction?.patient?.profile?.first_name?.decrypted,
  patient_last_name: (auction: Auction) =>
    auction?.patient?.profile?.last_name?.decrypted,
  patient_birthdate: auctionBirthdatetoUnix,
  insurance_number: (auction: Auction) =>
    auction.patient.profile?.financing?.insurance_number?.decrypted,
  gender: (auction: Auction) => auction.patient.profile?.gender,
  admission_date: (auction: Auction) => auction.patient.profile?.admission_date,
  discharge_date: (auction: Auction) => auction.patient.profile?.discharge_date,
  icd_code_1_code: (auction: Auction) => getICDCode(auction, 1),
  icd_code_2_code: (auction: Auction) => getICDCode(auction, 2),
  icd_code_3_code: (auction: Auction) => getICDCode(auction, 3),
  icd_code_4_code: (auction: Auction) => getICDCode(auction, 4),
  icd_code_5_code: (auction: Auction) => getICDCode(auction, 5),
  performed_treatment: (auction: Auction) => {
    if (
      !!auction?.patient.diagnosis?.hospital_stay?.operation_date ||
      !!auction?.patient.diagnosis?.hospital_stay?.operation_description
    ) {
      return PERFORMED_TREATMENT.SURGERY;
    }
  },
  operation_date: (auction: Auction) =>
    auction?.patient.diagnosis?.hospital_stay?.operation_date,
  operation_description: (auction: Auction) =>
    auction?.patient.diagnosis?.hospital_stay?.operation_description,
  rehabilitation_goals: (auction: Auction) =>
    auction.patient.diagnosis?.rehabilitation_goals,
  transport_type: (auction: Auction) => {
    const transportType = auction?.patient?.transport?.transport_type;

    return getTransportMapping(transportType);
  },
  require_accompanying_person: (auction: Auction) =>
    booleanToPredicamentState(
      auction.patient.transport?.require_accompanying_person,
    ),
  rehab_admission_type: (auction: Auction) =>
    auction.patient.profile?.direct_transfer_necessary
      ? REHAB_ADMISSION.DIRECT
      : null,
  infections_state: (auction: Auction) =>
    auction.patient.diagnosis?.infection_and_germs_state,
  complication_in_treatment_process: (auction: Auction) => {
    if (
      auction.patient.diagnosis?.infection_and_germs_state ===
      PREDICAMENT_STATE_UNKNOWN
    )
      return PREDICAMENT_STATE_YES;
    return auction.patient.diagnosis?.infection_and_germs_state;
  },
  infections: (auction: Auction) =>
    getInfection(auction, getOntology, translations)?.slice(
      0,
      INFECTIONS_CHARACTER_LIMIT,
    ),
  singer_assessment_date: (auction: Auction) =>
    auction.patient.diagnosis?.dependency_diagnosis?.singer_assessment?.date,
  singer_assessment_form: (auction: Auction) =>
    auction.patient.diagnosis?.dependency_diagnosis?.singer_assessment?.form,
  singer_assessment_score: (auction: Auction) => {
    return auction.patient.diagnosis?.dependency_diagnosis?.singer_assessment
      ?.score;
  },
});
