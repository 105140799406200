import {
  AtHomeSituation,
  Guardian,
  HomeCareSituation,
  PredicamentState,
} from "core/types";
import { convertModelDefinition, valueDef } from "react-forms-state";

export type GKVGeneralFormData = {
  case_id?: string | null;
  communication_in_german_possible?: PredicamentState | null;
  communication_other_language?: string | null;
  domestic_situation_is_safe?: PredicamentState | null;
  general_practitioner_city?: string | null;
  general_practitioner_name?: string | null;
  general_practitioner_phone_number?: string | null;
  general_practitioner_street?: string | null;
  general_practitioner_zipcode?: string | null;
  hospital_address?: string | null;
  hospital_name?: string | null;
  hospital_zipcode_city?: string | null;
  insurance_company_name?: string | null;
  insurance_number?: string | null;
  insured_details_application_insurance_benefits?: PredicamentState | null;
  insured_details_guardian_1_city?: string | null;
  insured_details_guardian_1_first_name?: string | null;
  insured_details_guardian_1_house_number?: string | null;
  insured_details_guardian_1_last_name?: string | null;
  insured_details_guardian_1_phone?: string | null;
  insured_details_guardian_1_street?: string | null;
  insured_details_guardian_1_type?: Guardian | null;
  insured_details_guardian_1_zipcode?: string | null;
  insured_details_guardian_2_city?: string | null;
  insured_details_guardian_2_first_name?: string | null;
  insured_details_guardian_2_house_number?: string | null;
  insured_details_guardian_2_last_name?: string | null;
  insured_details_guardian_2_phone?: string | null;
  insured_details_guardian_2_street?: string | null;
  insured_details_guardian_2_type?: Guardian | null;
  insured_details_guardian_2_zipcode?: string | null;
  living_lift?: PredicamentState | null;
  living_lift_description?: string | null;
  living_situation?: HomeCareSituation | null;
  living_situation_at_home?: AtHomeSituation | null;
  living_special?: string | null;
  patient_birthdate?: number | null;
  patient_city_zipcode?: string | null;
  patient_name?: string | null;
  patient_phone_number?: string | null;
  patient_street_housenumber?: string | null;
  prefered_clinic_contacted?: PredicamentState | null;
  prefered_clinic_contacted_details?: string | null;
  prefered_clinic_contacted_start_date?: number | null;
  reason_for_reha_clinic?: string | null;
  retirement_earning_capacity_company?: string | null;
  retirement_pension_applied?: PredicamentState | null;
  retirement_pension_company?: string | null;
  retirement_pension_earning_capacity_applied?: PredicamentState | null;
  social_worker_fax_number?: string | null;
  social_worker_full_name?: string | null;
  social_worker_phone_number?: string | null;
};

export const GKVgeneralFormDefinition = convertModelDefinition({
  // insured person
  ...valueDef("patient_name"),
  ...valueDef("patient_street_housenumber"),
  ...valueDef("patient_city_zipcode"),
  ...valueDef("patient_phone_number"),
  ...valueDef("insurance_company_name"),
  ...valueDef("insurance_number"),
  ...valueDef("patient_birthdate"),

  // section one
  ...valueDef("insured_details_application_insurance_benefits"),
  ...valueDef("retirement_pension_applied"),
  ...valueDef("retirement_pension_company"),
  ...valueDef("retirement_pension_earning_capacity_applied"),
  ...valueDef("retirement_earning_capacity_company"),
  ...valueDef("living_lift_description"),
  ...valueDef("living_lift"),
  ...valueDef("living_special"),
  ...valueDef("living_situation"),
  ...valueDef("living_situation_at_home"),
  ...valueDef("domestic_situation_is_safe"),
  ...valueDef("general_practitioner_name"),
  ...valueDef("general_practitioner_street"),
  ...valueDef("general_practitioner_zipcode"),
  ...valueDef("general_practitioner_city"),
  ...valueDef("general_practitioner_phone_number"),
  ...valueDef("insured_details_guardian_1_type"),
  ...valueDef("insured_details_guardian_1_first_name"),
  ...valueDef("insured_details_guardian_1_last_name"),
  ...valueDef("insured_details_guardian_1_street"),
  ...valueDef("insured_details_guardian_1_house_number"),
  ...valueDef("insured_details_guardian_1_zipcode"),
  ...valueDef("insured_details_guardian_1_city"),
  ...valueDef("insured_details_guardian_1_phone"),
  ...valueDef("insured_details_guardian_2_type"),
  ...valueDef("insured_details_guardian_2_first_name"),
  ...valueDef("insured_details_guardian_2_last_name"),
  ...valueDef("insured_details_guardian_2_street"),
  ...valueDef("insured_details_guardian_2_house_number"),
  ...valueDef("insured_details_guardian_2_zipcode"),
  ...valueDef("insured_details_guardian_2_city"),
  ...valueDef("insured_details_guardian_2_phone"),
  ...valueDef("reason_for_reha_clinic"),

  // section two
  ...valueDef("hospital_name"),
  ...valueDef("hospital_address"),
  ...valueDef("hospital_zipcode_city"),
  ...valueDef("case_id"),
  ...valueDef("social_worker_full_name"),
  ...valueDef("social_worker_phone_number"),
  ...valueDef("social_worker_fax_number"),
  ...valueDef("communication_in_german_possible"),
  ...valueDef("communication_other_language"),
  ...valueDef("prefered_clinic_contacted"),
  ...valueDef("prefered_clinic_contacted_details"),
  ...valueDef("prefered_clinic_contacted_start_date"),
});
