import { z } from "zod";

export const GKVGeneralFormSchema = z
  .object({
    insured_details_guardian_1_type: z
      .union([z.literal(1), z.literal(2), z.literal(3)])
      .nullable()
      .optional(),
    insured_details_guardian_1_first_name: z
      .string()
      .max(45)
      .nullable()
      .optional(),
    insured_details_guardian_1_last_name: z
      .string()
      .max(45)
      .nullable()
      .optional(),
    insured_details_guardian_1_street: z.string().max(46).nullable().optional(),
    insured_details_guardian_1_house_number: z
      .string()
      .max(9)
      .nullable()
      .optional(),
    insured_details_guardian_1_zipcode: z
      .string()
      .max(10)
      .nullable()
      .optional(),
    insured_details_guardian_1_city: z.string().max(40).nullable().optional(),
    insured_details_guardian_1_phone: z.string().max(25).nullable().optional(),

    insured_details_guardian_2_type: z
      .union([z.literal(1), z.literal(2), z.literal(3)])
      .nullable()
      .optional(),
    insured_details_guardian_2_first_name: z
      .string()
      .max(45)
      .nullable()
      .optional(),
    insured_details_guardian_2_last_name: z
      .string()
      .max(45)
      .nullable()
      .optional(),
    insured_details_guardian_2_street: z.string().max(46).nullable().optional(),
    insured_details_guardian_2_house_number: z
      .string()
      .max(9)
      .nullable()
      .optional(),
    insured_details_guardian_2_zipcode: z
      .string()
      .max(10)
      .nullable()
      .optional(),
    insured_details_guardian_2_city: z.string().max(40).nullable().optional(),
    insured_details_guardian_2_phone: z.string().max(25).nullable().optional(),
  })
  .superRefine((data, ctx) => {
    const validateGuardian = (prefix: "1" | "2") => {
      const type = data[`insured_details_guardian_${prefix}_type`];
      const first = data[`insured_details_guardian_${prefix}_first_name`];
      const last = data[`insured_details_guardian_${prefix}_last_name`];

      // Validate if any one of the 3 is present
      const hasAny = !!type || !!first || !!last;

      if (hasAny) {
        if (!type) {
          ctx.addIssue({
            path: [`insured_details_guardian_${prefix}_type`],
            code: z.ZodIssueCode.custom,
            message: `Vertretungstyp ist erforderlich, wenn Name angegeben ist`,
          });
        }
        if (!first) {
          ctx.addIssue({
            path: [`insured_details_guardian_${prefix}_first_name`],
            code: z.ZodIssueCode.custom,
            message: `Vorname ist erforderlich, wenn Vertretungstyp angegeben ist`,
          });
        }
        if (!last) {
          ctx.addIssue({
            path: [`insured_details_guardian_${prefix}_last_name`],
            code: z.ZodIssueCode.custom,
            message: `Nachname ist erforderlich, wenn Vertretungstyp angegeben ist`,
          });
        }
      }
    };

    validateGuardian("1");
    validateGuardian("2");
  });
